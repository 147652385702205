import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import Image from 'next/image';
import styled from 'styled-components';
import {
  CFAsset, CFImage, CFLink, EmbeddedVideo,
} from '../../lib/types';
import { Theme } from '../../styles/themes';
import ArrowLink from '../components/ArrowLink';
import { AutoButton } from '../components/Button';
import DotLottiePlayer from '../components/DotLottiePlayer';
import VideoInlinePlayer from '../components/InlineVideoPlayer';
import { AutoLink } from '../components/Link';
import ModulePaddingWrapper, { Padding } from '../components/ModulePaddingWrapper';
import { ContentfulAsset, getRenderOption } from '../helpers/RichTextHelpers';

export type MediaPadding = 'None' | 'Small' | 'Medium' | 'Large';

export const CONTENT_MODULE_MODULE_TYPE_ID = 'content_module';

type Props = {
  intunioModuleTypeId: typeof CONTENT_MODULE_MODULE_TYPE_ID,
  mediaBeforeContent: boolean,
  paddingTop?: Padding,
  paddingBottom?: Padding,
  roundedCorners: boolean,
  mediaPadding?: MediaPadding,
  singleColumn: boolean,
  buttonPrimary?: CFLink,
  buttonSecondary?: CFLink,
  theme: Theme;
  content?: {
    json: Object
    links: {
      entries: {block: Array<ContentfulAsset>};
      assets: {block: Array<ContentfulAsset>};
    };
  }
  image?: CFImage | CFAsset
  backgroundImage?: CFImage | CFAsset
  callToActionBottomLink?: CFLink
  wistiaVideo?: EmbeddedVideo
  anchorId?: string
}

const mediaPaddings = {
  None: '0px',
  Small: '32px',
  Medium: '64px',
  Large: '128px',
};

const mediaPaddingsMobile = {
  None: '0px',
  Small: '0px',
  Medium: '24px',
  Large: '48px',
};

const Container = styled.div<{
  mediaBeforeContent: boolean
  singleColumn: boolean
}>`
  display: grid;
  gap: 48px 32px;
  grid-template-columns: ${(props) => (props.singleColumn ? '1fr' : '1fr 1fr')};
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    grid-template-columns: 1fr;
  }
`;

const MediaContainer = styled.div<{
  mediaBeforeContent: boolean
  roundedCorners: boolean
  mediaPadding: MediaPadding
}>`
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    height: auto;
    border-radius: ${(props) => (props.roundedCorners ? '16px' : '0')};
  }
  order: ${(props) => (props.mediaBeforeContent ? '-1' : '1')};
  padding: 0 ${(props) => mediaPaddings[props.mediaPadding]};
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    padding: 0 ${(props) => mediaPaddingsMobile[props.mediaPadding]};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    padding: 0 ${(props) => mediaPaddings[props.mediaPadding]};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    padding: 0;
  }
`;

export const Emphasized = styled.div`
  display: inline;
`;

type TextContentProps = {
  singleColumn: boolean
}

const TextContent = styled.div<TextContentProps>`
  box-sizing: border-box;
  display: grid;
  font-size: 18px;
  width: 100%;
  max-width: 864px;
  margin: auto;
  padding: 0 64px;
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    padding: 0 32px;
    font-size: 16px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    padding: 0;
  }
  h2 {
    font-size: 32px;
    margin-top: 0;
    color: var(--theme-heading-color);
    text-align: ${(props) => (props.singleColumn ? 'center' : 'left')};

    @media (max-width: ${(props) => props.theme.breakpoints.large}) {
      font-size: 28px;
    }

    i {
      color: var(--theme-emphasized-color);
      font-style: normal;
      font-family: 'marydale';
      font-weight: bold;
      font-size: 1.2em;
    }
  }

  h3 {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 10px;

    color: var(--theme-subheading-color);
    text-align: ${(props) => (props.singleColumn ? 'center' : 'left')};
  }
`;

const RichText = styled.div`
  color: var(--theme-text-color);
  white-space: pre-wrap;
  a, .link {
    color: var(--theme-link-color);
    text-decoration: none;
    font-weight: 600;
  }
  ul {
    list-style: none;
    display: grid;
    gap: 12px;
    margin: 0;
    padding: 0 0 0 32px;
  }
  li {
    display: flex;

    p {
      margin: 0;
      width: 100%;
    }
  }
  li::before {
    content: "»";
    color: var(--theme-detail-color);
    display: inline-block;
    width: 1em;
    margin-left: -1em
  }
  p {
    margin-bottom: 24px;
    margin-top: 0;
  }
`;

const ButtonContainer = styled.div<{
  singleColumn: boolean
}>`
  margin-top: 24px;
  gap: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.singleColumn ? 'center' : 'flex-start')};
`;

const ContentModule = ({
  content,
  theme,
  image,
  backgroundImage,
  mediaBeforeContent,
  singleColumn,
  buttonPrimary,
  buttonSecondary,
  roundedCorners,
  mediaPadding = 'Small',
  paddingTop,
  paddingBottom,
  callToActionBottomLink,
  wistiaVideo,
  anchorId,
}: Props) => (
  <ModulePaddingWrapper
    theme={theme}
    paddingTop={paddingTop}
    paddingBottom={paddingBottom}
    backgroundImage={backgroundImage?.url}
    id={anchorId}
  >
    <Container
      mediaBeforeContent={mediaBeforeContent}
      singleColumn={singleColumn}
    >
      {(wistiaVideo || image) && (
        <MediaContainer
          mediaBeforeContent={mediaBeforeContent}
          mediaPadding={mediaPadding}
          roundedCorners={roundedCorners}
        >
          {wistiaVideo ? (
            <VideoInlinePlayer {...wistiaVideo} />
          ) : (image!.contentType === 'application/zip'
            ? (
              <DotLottiePlayer mode="normal" src={image!.url} loop autoplay />
            )
            : (
              (image!.contentType.startsWith('video') ? <video src={image!.url} autoPlay loop muted /> : (
                'width' in image! && (
                <Image
                  sizes={singleColumn
                    ? '(max-width: 1440px) 100vw, 1440px'
                    : '(max-width: 600px) 90vw, (max-width: 900px) 80vw, (max-width: 1570px) 50vw, 720px'}
                  width={image.width}
                  height={image.height}
                  src={image.url}
                  alt={image?.description || image?.title}
                />
                )
              )
              )))}

        </MediaContainer>
      )}

      <TextContent singleColumn={singleColumn}>
        <article>
          <RichText>
            {content && documentToReactComponents(
              content.json as Document,
              getRenderOption(content),
            )}
          </RichText>
          {callToActionBottomLink && <AutoLink as={ArrowLink} {...callToActionBottomLink} />}
          {(!!buttonPrimary || !!buttonSecondary) && (
            <ButtonContainer singleColumn={singleColumn}>
              {buttonPrimary && <AutoButton {...buttonPrimary} />}
              {buttonSecondary && <AutoButton {...buttonSecondary} />}
            </ButtonContainer>
          )}
        </article>
      </TextContent>
    </Container>
  </ModulePaddingWrapper>
);

export default ContentModule;
