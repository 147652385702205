import styled from 'styled-components';

const Button = styled.button`
  cursor: pointer;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

const Svg = styled.svg`
  circle {
    fill: var(--theme-slider-button-normal);
  }
  path {
    fill: var(--theme-slider-arrow-normal);
  }
  &:hover {
    circle {
      fill: var(--theme-slider-button-hover);
    }
  }
  &:active {
    circle {
      fill: var(--theme-slider-button-active);
    }
    path {
      fill: var(--theme-slider-arrow-active);
    }
  }
`;

export const LeftSliderButton = () => (
  <Button>
    <Svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" />
      <path fillRule="evenodd" clipRule="evenodd" d="M18.3905 24.9428C17.8698 24.4221 17.8698 23.5779 18.3905 23.0572L26.3905 15.0572C26.9112 14.5365 27.7554 14.5365 28.2761 15.0572C28.7968 15.5779 28.7968 16.4221 28.2761 16.9428L21.219 24L28.2761 31.0572C28.7968 31.5779 28.7968 32.4221 28.2761 32.9428C27.7554 33.4635 26.9112 33.4635 26.3905 32.9428L18.3905 24.9428Z" />
    </Svg>
  </Button>
);

export const RightSliderButton = () => (
  <Button>
    <Svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" />
      <path fillRule="evenodd" clipRule="evenodd" d="M29.6095 23.057C30.1302 23.5777 30.1302 24.4219 29.6095 24.9426L21.6095 32.9426C21.0888 33.4633 20.2446 33.4633 19.7239 32.9426C19.2032 32.4219 19.2032 31.5777 19.7239 31.057L26.781 23.9998L19.7239 16.9426C19.2032 16.4219 19.2032 15.5777 19.7239 15.057C20.2446 14.5363 21.0888 14.5363 21.6095 15.057L29.6095 23.057Z" />
    </Svg>
  </Button>
);
