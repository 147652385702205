import styled from 'styled-components';
import { gql } from '@apollo/client';
import ModulePaddingWrapper, { Padding } from '../components/ModulePaddingWrapper';
import { Theme } from '../../styles/themes';

export const HEADING_MODULE_MODULE_TYPE_ID = 'heading_module';

type Props = {
  intunioModuleTypeId: typeof HEADING_MODULE_MODULE_TYPE_ID,
  heading?: string
  subheading?: string
  paddingTop?: Padding
  paddingBottom?: Padding
  theme: Theme
}

export const headingModule = gql`
fragment headingModule on HeadingModule {
  intunioModuleTypeId
  heading
  subheading
  paddingTop
  paddingBottom
  theme
}`;

const Container = styled.div`
  text-align: center;
  display: grid;
  gap: 8px 0;
  h2, h3 {
    margin: 0;
    padding: 0;
  }
  h2 {
    color: var(--theme-heading-color);
  }
  h3 {
    color: var(--theme-subheading-color);
  }
`;

export default ({
  heading, subheading, theme, paddingTop, paddingBottom, intunioModuleTypeId,
} : Props) => (
  <ModulePaddingWrapper theme={theme} paddingTop={paddingTop} paddingBottom={paddingBottom}>
    <Container id={intunioModuleTypeId}>
      {subheading && <h3>{subheading}</h3>}
      {heading && <h2>{heading}</h2>}
    </Container>
  </ModulePaddingWrapper>
);
