import { gql } from '@apollo/client';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';
import { CFImage, WithId } from '../../lib/types';
import { Theme } from '../../styles/themes';
import ModulePaddingWrapper, { Padding } from '../components/ModulePaddingWrapper';

export const CUSTOMER_LOGOS_MODULE_MODULE_TYPE_ID = 'customer_logos';

type Props = {
  intunioModuleTypeId: typeof CUSTOMER_LOGOS_MODULE_MODULE_TYPE_ID,
  heading?: string,
  theme?: Theme,
  paddingTop?: Padding,
  paddingBottom?: Padding,
  logos: WithId<CFImage>[],
}

export const customerLogosModule = gql`
fragment customerLogosModule on CustomerLogosModule {
  intunioModuleTypeId
  heading
  theme
  paddingTop
  paddingBottom
  logosCollection(limit: 48) {
    items {...imageWithId}
  }
}`;

const NUM_LOGOS_PER_PAGE = 4;

const Logo = styled.div`
  width: 140px;
  height: 100px;
  overflow: hidden;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  display: flex;
  box-shadow: ${(props) => props.theme.shadows.hard};
  border-radius: 12px;
  background: white;
  border: 1px solid var(--theme-border-color);
  img {
    max-width: 100%;
  }
  @media (max-width: 340px) {
    width: 105px;
    height: 75px;
  }
`;

type GridProps = {
  numLogos: number
}

type Breakpoint = 0 | 1 | 2 | 3

const columnsByNumLogos = (numLogos: number) => {
  if (numLogos > 15) {
    return [6, 4, 3];
  }
  switch (numLogos) {
    case 1: return [1, 1, 1];
    case 2: return [2, 2, 2];
    case 3: return [3, 3, 3];
    case 4: return [4, 4, 2];
    case 5: return [5, 3, 3];
    case 6: return [6, 3, 3];
    case 7: return [4, 4, 3];
    case 8: return [4, 4, 3];
    case 9: return [5, 3, 3];
    case 10: return [5, 4, 3];
    case 11: return [6, 4, 3];
    case 12: return [6, 4, 3];
    case 13: return [5, 4, 3];
    case 14: return [5, 4, 3];
    case 15: return [5, 4, 3];
    default: return [1, 1, 1];
  }
};

const getWidth = (bp: Breakpoint, numLogos: number, logoWidth: number, gapWidth: number) => {
  const columns = columnsByNumLogos(numLogos)[bp];
  return logoWidth * columns + (columns - 1) * gapWidth;
};

const Grid = styled.div<GridProps>`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  gap: 32px;
  justify-content: center;
  width: ${(props) => getWidth(0, props.numLogos, 140, 32)}px;
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    gap: 24px;
    width: ${(props) => getWidth(1, props.numLogos, 140, 24)}px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    width: ${(props) => getWidth(2, props.numLogos, 140, 24)}px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    display: none;
  }
`;

const LogoCarousel = styled.div`
  display: none;
  overflow: hidden;
  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    display: block;
  }
`;

type GridContainerProps = {
  currentPage: number
}

const LogoCarouselGridContainer = styled.div<GridContainerProps>`
  display: flex;
  transform: translateX(${(props) => -100 * props.currentPage}%);
  transition: transform 0.4s;
`;

const LogoCarouselPage = styled.div`
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoCarouselGrid = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
`;

const PageIndicators = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  justify-content: center;
  margin-top: 32px;
`;

const PageIndicator = styled.div`
  width: 24px;
  border-radius: 4px;
  height: 4px;
  background: var(--theme-border-color);
  cursor: pointer;
  &.active {
    background: var(--color-bluescape-v100);
  }
`;

const Heading = styled.div`
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 56px;
  padding: 0 64px;
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    margin-bottom: 48px;
    font-size: 28px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    margin-bottom: 40px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    padding: 0 24px;
    margin-bottom: 32px;
  }
`;

export default ({
  intunioModuleTypeId, heading, theme = 'Bluescape Bleach', logos, paddingTop = 'Medium', paddingBottom = 'Medium',
} : Props) => {
  const [currentCarouselPage, setCurrentCarouselPage] = useState(0);
  const numCarouselPages = Math.ceil(logos.length / NUM_LOGOS_PER_PAGE);
  const pageRef = useRef<HTMLDivElement>(null);

  const updatePageSwipeOffset = (val: number) => {
    if (!pageRef.current) return;
    pageRef.current.style.translate = `${val}px 0`;
  };

  const prev = () => {
    setCurrentCarouselPage((currentCarouselPage + numCarouselPages - 1) % numCarouselPages);
  };

  const next = () => {
    setCurrentCarouselPage((currentCarouselPage + 1) % numCarouselPages);
  };

  useEffect(() => {
    const id = setInterval(next, 5000);
    return () => {
      clearInterval(id);
    };
  });
  const pages = [];
  for (let i = 0; i < numCarouselPages; i += 1) {
    pages.push(i);
  }
  const showPageIndicators = pages.length > 1;

  const handlers = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: prev,
    onSwiping: (e) => {
      if (!showPageIndicators) return;
      updatePageSwipeOffset(e.deltaX);
    },
  });

  return (
    <ModulePaddingWrapper theme={theme} paddingTop={paddingTop} paddingBottom={paddingBottom}>
      {heading && (
        <Heading>{heading}</Heading>
      )}
      <LogoCarousel {...handlers} id={intunioModuleTypeId}>
        <LogoCarouselGridContainer currentPage={currentCarouselPage}>
          {pages.map((page) => {
            const from = page * NUM_LOGOS_PER_PAGE;
            const to = page * NUM_LOGOS_PER_PAGE + NUM_LOGOS_PER_PAGE;
            const slice = logos.slice(from, to);
            if (pageRef.current) {
              const oldRef = pageRef.current;

              // Wait for animation to finish
              setTimeout(() => {
                oldRef.style.translate = '';
              }, 300);
            }
            return (
              <LogoCarouselPage ref={page === currentCarouselPage ? pageRef : undefined} key={page}>
                <LogoCarouselGrid>
                  {slice.map((logo) => (
                    <Logo key={logo.sys.id}>
                      <Image width="108" height="92" src={logo.url} alt={logo.description || logo.title} />
                    </Logo>
                  ))}
                </LogoCarouselGrid>
              </LogoCarouselPage>
            );
          })}
        </LogoCarouselGridContainer>
        {showPageIndicators && (
          <PageIndicators>
            {pages.map((page) => (
              <PageIndicator
                key={page}
                onClick={() => setCurrentCarouselPage(page)}
                className={currentCarouselPage === page ? 'active' : ''}
              />
            ))}
          </PageIndicators>
        )}
      </LogoCarousel>
      <Grid numLogos={logos.length}>
        {logos.map((logo) => (
          <Logo key={logo.sys.id}>
            <Image width="108" height="92" src={logo.url} alt={logo.description || logo.title} />
          </Logo>
        ))}
      </Grid>
    </ModulePaddingWrapper>
  );
};
