import { gql } from '@apollo/client';
import Image from 'next/image';
import styled from 'styled-components';
import {
  CFImage, CFLink,
} from '../../lib/types';
import { Theme } from '../../styles/themes';
import ArrowLink from '../components/ArrowLink';
import { AutoLink } from '../components/Link';
import ModulePaddingWrapper, { Padding } from '../components/ModulePaddingWrapper';

export const SIMPLE_QUOTE_MODULE_MODULE_TYPE_ID = 'simple_quote_module';

type Props = {
  intunioModuleTypeId: typeof SIMPLE_QUOTE_MODULE_MODULE_TYPE_ID,
  paddingTop: Padding
  paddingBottom: Padding,
  fontVariant: 'Large' | 'Small',
  link?: CFLink,
  image?: CFImage,
  quote: string,
  authorName?: string,
  authorTitle?: string,
  theme: Theme
  anchorId?: string
}

export const simpleQuoteModule = gql`
fragment simpleQuoteModule on SimpleQuoteModule {
  intunioModuleTypeId
  paddingTop
  paddingBottom
  fontVariant
  link {...link}
  image {...image}
  quote
  authorName
  authorTitle
  theme
  anchorId
}`;

const H2 = styled.div`
  font-size: 32px;
  font-weight: 400;
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    font-size: 28px;
  }
`;

const H3 = styled.div`
  font-size: 24px;
  font-weight: 400;
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    font-size: 22px;
  }
`;

const QuotesIcon = () => (
  <svg width="44" height="35" viewBox="0 0 44 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.1137 34.9412C16.5782 34.9412 20.019 30.1433 20.019 25.2411C20.019 20.3389 16.5782 15.541 9.80095 15.541C11.0521 10.9517 13.346 6.98824 16.5782 2.81616L13.2417 0C7.40284 5.63231 0 16.4797 0 24.0938C0 30.1433 3.64929 34.9412 10.1137 34.9412ZM34.0948 34.9412C40.5592 34.9412 44 30.1433 44 25.2411C44 20.3389 40.5592 15.541 33.782 15.541C35.0332 10.9517 37.327 6.98824 40.5592 2.81616L37.2228 0C31.3839 5.63231 23.981 16.4797 23.981 24.0938C23.981 30.1433 27.6303 34.9412 34.0948 34.9412Z" fill="var(--theme-simple-quote-color)" />
  </svg>
);

const Container = styled.div`
  display: grid;
  max-width: 1086px;
  margin: 0 auto;
  gap: 48px 32px;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    grid-template-columns: 1fr;
  }
`;

export const SubHeading = styled.div`
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const PrimaryHeading = styled.div`
  font-size: 32px;
  margin-bottom: 24px;
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    font-size: 28px;
  }
`;

const MainColumn = styled.div`
  display: grid;
  grid-template-columns: 44px auto;
  gap: 24px 16px;
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    grid-template-columns: auto;
  }
`;

type GridProps = {
  hasImage: boolean
}

const Grid = styled.div<GridProps>`
  display: grid;
  gap: 48px;
  align-items: center;
  @media (min-width: 900px) {
    grid-template-columns: ${(props) => (props.hasImage ? 'auto 220px' : 'auto')};
  }
`;

const Biography = styled.div`
`;

const Author = styled.span`
  font-weight: 600;
`;

const TextContent = styled.div`
  display: grid;
  gap: 16px;
`;

export default ({
  intunioModuleTypeId,
  link,
  image,
  quote,
  authorName,
  authorTitle,
  fontVariant,
  paddingTop,
  paddingBottom,
  theme,
  anchorId,
} : Props) => {
  const Heading = fontVariant === 'Large' ? H2 : H3;
  return (
    <ModulePaddingWrapper
      theme={theme}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      id={anchorId}
    >
      <Container id={intunioModuleTypeId}>
        <Grid hasImage={!!image}>
          <MainColumn>
            <QuotesIcon />
            <TextContent>
              <Heading>
                {quote}
              </Heading>
              <Biography>
                {authorName && (
                  <Author>
                    {`— ${authorName}`}
                  </Author>
                )}
                {authorTitle && (
                  <div>
                    {authorTitle}
                  </div>
                )}
              </Biography>
              {link && <AutoLink as={ArrowLink} {...link} />}
            </TextContent>
          </MainColumn>
          {image && (
            <Image width="220" height="220" src={image.url} alt={image.description || image.title} />
          )}
        </Grid>
      </Container>
    </ModulePaddingWrapper>
  );
};
