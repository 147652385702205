import Image from 'next/image';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import InternalLink from '../components/ArrowLink';
import {
  CFImage, CFLink, WithId,
} from '../../lib/types';
import { AutoLink } from '../components/Link';
import ModulePaddingWrapper from '../components/ModulePaddingWrapper';

export const LATEST_UPDATES_MODULE_MODULE_TYPE_ID = 'latest_updates';

type Props = {
  intunioModuleTypeId: typeof LATEST_UPDATES_MODULE_MODULE_TYPE_ID,
  heading: string,
  latestUpdatesModuleItems?: WithId<{
    title: string,
    subtitle: string,
    date: string,
    image?: CFImage,
    /** default in contentful is center */
    imagePosition?: string,
    link: CFLink
  }>[],
  link?: {
    title: string,
    href: string
  }
}

export const latestUpdatesModule = gql`
fragment latestUpdatesModule on LatestUpdatesModule {
  intunioModuleTypeId
  heading
  latestUpdatesModuleItemsCollection(limit: 10) {
    items {
      sys { id }
      title
      subtitle
      date
      image {...image}
      imagePosition
      link {...link}
    }
  }
}`;

const CardSubtitle = styled.div.attrs({ role: 'doc-subtitle' })`
  font-size: 15px;
  color: var(--color-charcoal-v50);
  font-weight: 600;
  margin-bottom: 4px;
`;

const CardTitle = styled.h3`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
`;

type CardStackProps = {
  numCards: number
}

const BaseCard = styled.article`
  border-radius: 12px;
  background: white;
  box-shadow: ${(props) => props.theme.shadows.hard};
  border: 1px solid var(--theme-border-color);
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  cursor: pointer;
  img {
    width: 100%;
    height: 218px;
    object-fit: cover;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    img {
      width: 472px;
      height: 236px;
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    height: 200px;
    grid-template-rows: auto;
    grid-template-columns: 300px auto;
    img {
      width: 300px;
      height: 200px;
      border-radius: 0;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    height: auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &:hover {
    border: 1px solid var(--theme-border-color-hover);
  }
`;

const CardStack = styled.div<CardStackProps>`
  display: grid;
  gap: 24px;
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    gap: 16px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    ${BaseCard} {
      grid-template-columns: auto 1fr;
      img {
        height: ${(props) => (props.numCards === 2 ? '232px' : '146px')};
        width: ${(props) => (props.numCards === 2 ? '290px' : '219px')};
      }
    }
  }
`;

const CardDate = styled.time`
  font-size: 15px;
  color: var(--color-charcoal-v50);
  margin-top: 8px;
`;

const LargeCard = styled(BaseCard)`
  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    height: 492px;
    grid-template-rows: 1fr auto;
    grid-template-columns: auto;
    img {
      max-height: 340px;
      border-radius: 0;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
    ${CardTitle} {
      font-size: 24px;
    }
    ${CardSubtitle} {
      font-size: 18px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: fit-content;
  margin: 0 auto;

  a, .link {
    color: inherit;
  }
`;

const Heading = styled.h2`
  font-size: 32px;
  margin: 0;
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    font-size: 28px;
  }
`;

type GridProps = {
  stacked: boolean
}

const Grid = styled.div<GridProps>`
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) => (props.stacked ? 'auto 640px' : '1fr 1fr')};
  gap: 24px 32px;
  @media (min-width: ${(props) => props.theme.breakpoints.small}) and (max-width: ${(props) => props.theme.breakpoints.medium}) {
    max-width: 472px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    grid-template-columns: auto;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    gap: 16px;
  }
`;

const CardBody = styled.div`
  padding: 24px;
  grid-template-columns: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
`;

const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type CardContentProps = Exclude<Props['latestUpdatesModuleItems'], undefined>[0] & {large?: boolean};

const CardContent = ({
  title, subtitle, date, image, large, imagePosition = 'center',
} : CardContentProps) => (
  <>
    {image && (
      <Image
        width={image.width}
        style={{ objectPosition: imagePosition }}
        height={image.height}
        src={image.url}
        alt={image.description || image.title}
        sizes={large ? '(max-width: 900px) 472px, (max-width: 1200px) 300px, (max-width: 1570px) 50vw, 766px' : '(max-width: 900px) 472px, (max-width: 1200px) 300px, 440px'}
      />
    )}
    <CardBody>
      <div>
        <CardSubtitle>{subtitle}</CardSubtitle>
        <CardTitle>{title}</CardTitle>
      </div>
      <CardDate>{date}</CardDate>
    </CardBody>
  </>
);

export default ({
  heading,
  latestUpdatesModuleItems = [],
  link,
} : Props) => {
  /**
   * This component requires at least two cards to render. If less than two
   * cards are supplied we will render placeholders instead.
   */
  const placeholderCard = {
    id: 0,
    subtitle: 'Placeholder',
    title: 'This is a placeholder, this component needs at least two cards to render',
    date: 'Feb 14, 2022',
    image: {
      url: '/images/creative.png',
      description: 'Placeholder image',
      width: 1248,
      height: 800,
    },
  };
  const firstCard = latestUpdatesModuleItems?.[0] ?? placeholderCard;
  const secondCard = latestUpdatesModuleItems?.[1] ?? placeholderCard;

  return (
    <ModulePaddingWrapper
      theme="Charcoal Bleach"
      paddingTop="Large"
    >
      <Wrapper>
        <HeadContainer>
          <Heading>
            {heading}
          </Heading>
          {link && (
            <InternalLink {...link} />
          )}
        </HeadContainer>
        <Grid stacked={latestUpdatesModuleItems.length > 2}>
          <AutoLink {...firstCard.link}>
            <LargeCard>
              <CardContent large {...firstCard} />
            </LargeCard>
          </AutoLink>
          {latestUpdatesModuleItems.length > 2 ? (
            <CardStack numCards={latestUpdatesModuleItems.length - 1}>
              {latestUpdatesModuleItems.slice(1).map((card) => (
                <AutoLink key={card.sys.id} {...card.link}>
                  <BaseCard key={card.sys.id}>
                    <CardContent {...card} />
                  </BaseCard>
                </AutoLink>
              ))}
            </CardStack>
          ) : (
            <AutoLink {...secondCard.link}>
              <LargeCard>
                <CardContent large {...secondCard} />
              </LargeCard>
            </AutoLink>
          )}
        </Grid>
      </Wrapper>
    </ModulePaddingWrapper>
  );
};
