import styled, { keyframes } from 'styled-components';
import { AnimationEventHandler } from 'react';

type ProgressProps = {
  duration: string
}

const scaleX = keyframes`
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
`;

const Progress = styled.div<ProgressProps>`
  height: 4px;
  background: var(--theme-emphasized-color);
  width: 100%;
  animation: ${scaleX} ${(props) => props.duration} linear;
  border-radius: 100px;
  transform-origin: center left;
`;

const ProgressContainer = styled.div`
  height: 4px;
  background: var(--theme-border-color);
  border-radius: 2px;
  overflow: hidden;
`;

type Props = {
  onAnimationEnd: AnimationEventHandler<HTMLDivElement>
  duration: string
}

export default ({ duration, onAnimationEnd } : Props) => (
  <ProgressContainer>
    <Progress duration={duration} onAnimationEnd={onAnimationEnd} />
  </ProgressContainer>
);
