import { gql } from '@apollo/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import Image from 'next/image';
import { ComponentProps } from 'react';
import styled, { DefaultTheme, StyledComponent } from 'styled-components';
import {
  CFImage, CFLink,
} from '../../lib/types';
import { isDark, normalizeThemeClassName, Theme } from '../../styles/themes';
import { AutoButton } from '../components/Button';
import H3 from '../components/H3';
import Navbar from '../components/Navbar';
import PopsyncInput from '../components/PopsyncInput';
import { documentToOnlyHeaders } from '../helpers/RichTextHelpers';

export const SUBPAGE_HEADER_MODULE_TYPE_ID = 'subpage_header';

/* NavbarData is added manually when mounting this component */
type Props = {
  intunioModuleTypeId: typeof SUBPAGE_HEADER_MODULE_TYPE_ID,
  theme: Theme,
  backgroundColor?: string,
  subheading?: string,
  subpageHeaderHeading?: {
    json: Object
  },
  body?: {
    json: Object
  },
  foregroundImage?: CFImage
  backgroundImage?: CFImage
  primaryButton?: CFLink
  secondaryButton?: CFLink
  navbarData: ComponentProps<typeof Navbar>,
  bottomPadding: string,
  popsyncSearch: boolean,
}

export const subpageHeader = gql`
fragment subpageHeaderModule on SubpageHeader {
  intunioModuleTypeId
  theme
  backgroundColor
  subheading
  popsyncSearch
  subpageHeaderHeading {
    json
  }
  body {
    json
  }
  foregroundImage {...image}
  backgroundImage {...image}
  primaryButton {...link}
  secondaryButton {...link}
  bottomPadding
}`;

const Heading = styled.div`
  font-size: 40px;
  font-weight: 400;
  h1 {
    margin: 0;
    white-space: pre-line;
  }
  b {
    font-weight: 700;
  }
  @media (max-width: 1200px) {
    font-size: 34px;
  }
  i {
      color: var(--theme-emphasized-color);
      font-style: normal;
      font-family: 'marydale';
      font-weight: bold;
      font-size: 1.2em;
    }
`;

const Body = styled.div`
  font-size: 18px;
  @media (max-width: 1200px) {
    font-size: 16px;
  }
  i {
      color: var(--theme-emphasized-color);
      font-style: normal;
      font-family: 'marydale';
      font-weight: bold;
      font-size: 1.2em;
    }
  p:last-of-type {
    margin-bottom: 0;
  }
`;

type BackgroundProps = {
  darkTheme: boolean,
  color?: string,
  url?: string
}

const Background = styled.div<BackgroundProps>`
  color: ${(props) => (props.darkTheme ? 'white' : 'var(--color-charcoal-v100)')};
  background-color: ${(props) => props.color ?? 'var(--theme-background)'};
  background-image: ${(props) => (props.url ? (`url(${props.url})`) : 'none')};
  background-repeat: no-repeat;
  background-size: 100vw;
  background-position: center bottom;
  padding: 0 24px;
  @media (max-width: 600px) {
    background-image: none;
  }
`;

const NavbarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const ImageContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

type TextContainerProps = {
  hasButtons: boolean,
  variablePadding: boolean
}

const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  a, .link {
    display: inline-block;
  }
`;

const TextContainer = styled.div<TextContainerProps>`
  max-width: 680px;
  text-align: center;
  margin: 0 auto;
  padding-bottom: ${(props) => (props.variablePadding ? '20vw' : (props.hasButtons ? '60px' : '80px'))};
  padding-top: 160px;
  display: grid;
  gap: 24px;
  @media (max-width: 1200px) {
    padding-top: 124px;
    padding-bottom: ${(props) => (props.variablePadding ? '20vw' : (props.hasButtons ? '40px' : '60px'))};
    max-width: 600px;
  }
  @media (max-width: 900px) {
    padding-top: 112px;
  }
  @media (max-width: 600px) {
    padding-bottom: ${(props) => (props.hasButtons ? '24px' : '32px')};
    padding-top: 96px;
  }
`;

const GenericTextComponent = styled<StyledComponent<'div', DefaultTheme, TextContainerProps & { paddingMultiplier: number }>>(TextContainer)`
  padding-bottom: min(${(props) => (props.variablePadding ? '20vw' : (props.hasButtons ? '60px' : '80px'))}, 160px * ${(props) => props.paddingMultiplier});
  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    padding-bottom: min(${(props) => (props.variablePadding ? '20vw' : (props.hasButtons ? '40px' : '60px'))}, 124px * ${(props) => props.paddingMultiplier});
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding-bottom: min(${(props) => (props.variablePadding ? '10vw' : (props.hasButtons ? '32px' : '40px'))}, 112px * ${(props) => props.paddingMultiplier});
  }
`;

const BodyFreeTextContainer = (props: ComponentProps<typeof GenericTextComponent>) => (
  <GenericTextComponent paddingMultiplier={2} {...props}>
    {props.children}
  </GenericTextComponent>
);

const SmallPaddingTextContainer = (props: ComponentProps<typeof GenericTextComponent>) => (
  <GenericTextComponent paddingMultiplier={1 / 1.5} {...props}>
    {props.children}
  </GenericTextComponent>
);

const MediumPaddingTextContainer = (props: ComponentProps<typeof GenericTextComponent>) => (
  <GenericTextComponent paddingMultiplier={1} {...props}>
    {props.children}
  </GenericTextComponent>
);

const StyledH3 = styled(H3)`
  color: var(--theme-subheading-color);
  margin-bottom: -16px;
`;

export default ({
  subheading,
  subpageHeaderHeading,
  theme,
  backgroundColor,
  backgroundImage,
  foregroundImage,
  navbarData,
  body,
  primaryButton,
  secondaryButton,
  bottomPadding,
  popsyncSearch,
}: Props) => {
  const hasMoreContent = !!subheading || !!body;
  let RealTextContainer = hasMoreContent ? TextContainer : BodyFreeTextContainer;
  if (bottomPadding === 'Small') {
    RealTextContainer = SmallPaddingTextContainer;
  } else if (bottomPadding === 'Medium') {
    RealTextContainer = MediumPaddingTextContainer;
  }
  return (
    <Background
      className={normalizeThemeClassName(theme)}
      url={backgroundImage?.url}
      color={backgroundColor}
      darkTheme={isDark(theme)}
    >
      <NavbarContainer>
        <Navbar {...navbarData} darkTheme={isDark(theme)} />
      </NavbarContainer>
      <RealTextContainer
        hasButtons={!!primaryButton}
        variablePadding={!!backgroundImage && !foregroundImage}
      >
        {subheading && (
          <StyledH3 textAlign="center">
            {subheading}
          </StyledH3>
        )}
        {subpageHeaderHeading && (
          <Heading>
            {documentToOnlyHeaders(subpageHeaderHeading.json as Document, { level: 'h1' })}
          </Heading>
        )}
        {body && (
          <Body>
            {documentToReactComponents(body.json as Document)}
          </Body>
        )}
        <ButtonWrapper>
          {popsyncSearch && (
            <PopsyncInput noPadding />
          )}
          {primaryButton && (
            <div>
              <AutoButton {...primaryButton} />
            </div>
          )}
          {secondaryButton && (
            <div>
              <AutoButton {...secondaryButton} />
            </div>
          )}
        </ButtonWrapper>
      </RealTextContainer>
      <ImageContainer>
        {foregroundImage && (
          <Image
            width={foregroundImage.width}
            height={foregroundImage.height}
            src={foregroundImage.url}
            alt={foregroundImage.description || foregroundImage.title}
            priority
          />
        )}
      </ImageContainer>
    </Background>
  );
};
