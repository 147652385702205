import styled from 'styled-components';

type Props = {
  textAlign?: 'left' | 'right' | 'center'
}

export default styled.h3<Props>`
  font-size: 24px;
  text-align: ${(props) => props.textAlign ?? 'left'};
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    font-size: 22px;
  };
`;
