import { gql } from '@apollo/client';
import Image from 'next/image';
import { useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import styled, { keyframes } from 'styled-components';
import {
  CFImage, CFLink, WithId,
} from '../../lib/types';
import { Theme } from '../../styles/themes';
import ArrowLink from '../components/ArrowLink';
import { AutoLink } from '../components/Link';
import type { Padding } from '../components/ModulePaddingWrapper';
import ModulePaddingWrapper from '../components/ModulePaddingWrapper';
import ProgressBar from '../components/ProgressBar';
import { LeftSliderButton, RightSliderButton } from '../components/SliderButtons';
import Spacer from '../components/Spacer';

export const LARGE_QUOTE_MODULE_MODULE_TYPE_ID = 'large_quote_module';

const LOGO_WIDTH = 108;
const LOGO_GAP_MEDIUM_BREAKPOINT = 48;

type Props = {
  intunioModuleTypeId: typeof LARGE_QUOTE_MODULE_MODULE_TYPE_ID,
  paddingTop?: Padding,
  paddingBottom?: Padding,
  theme: Theme,
  largeQuoteModuleItems?: WithId<{
    author: string,
    authorTitle: string,
    body: string,
    image?: CFImage
    logo?: CFImage
    link?: CFLink
  }>[]
  anchorId?: string
}

export const largeQuoteModule = gql`
fragment largeQuoteModule on LargeQuoteModule {
  intunioModuleTypeId
  paddingTop
  paddingBottom
  theme
  largeQuoteModuleItemsCollection(limit: 10) {
    items {
      sys { id }
      author
      authorTitle
      body
      image {...image}
      logo {...image}
      link {...link}
    }
  }
  anchorId
}`;

const Carousel = styled.div`
  display: grid;
`;

const swipeInRight = keyframes`
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const swipeInLeft = keyframes`
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const swipeOutLeft = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(200px);
    opacity: 0;
  }
`;

const swipeOutRight = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-200px);
    opacity: 0;
  }
`;

const Quotes = () => (
  <svg width="44" height="35" viewBox="0 0 44 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.1137 34.9412C16.5782 34.9412 20.019 30.1433 20.019 25.2411C20.019 20.3389 16.5782 15.541 9.80095 15.541C11.0521 10.9517 13.346 6.98824 16.5782 2.81616L13.2417 0C7.40284 5.63231 0 16.4797 0 24.0938C0 30.1433 3.64929 34.9412 10.1137 34.9412ZM34.0948 34.9412C40.5592 34.9412 44 30.1433 44 25.2411C44 20.3389 40.5592 15.541 33.782 15.541C35.0332 10.9517 37.327 6.98824 40.5592 2.81616L37.2228 0C31.3839 5.63231 23.981 16.4797 23.981 24.0938C23.981 30.1433 27.6303 34.9412 34.0948 34.9412Z" fill="var(--theme-detail-color)" />
  </svg>
);

const NavigationButton = styled.div`
  position: relative;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  @media (max-width: 900px) {
    display: none;
  }
`;

type LogoGridProps = {
  activeIndex: number
}

const LogoGrid = styled.div<LogoGridProps>`
  display: grid;
  grid-auto-flow: column;
  gap: 32px;
  justify-self: center;
  transition: transform 0.4s;
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    width: ${LOGO_WIDTH}px;
    gap: ${LOGO_GAP_MEDIUM_BREAKPOINT}px;
    justify-self: center;
    transform: translateX(${(props) => -(LOGO_WIDTH + LOGO_GAP_MEDIUM_BREAKPOINT) * props.activeIndex}px);
  }
`;

const Logo = styled.div`
  cursor: pointer;
  img {
    display: block;
    width: ${LOGO_WIDTH}px;
    height: 92px;
    opacity: 0.4;
    transition: 250ms;
    margin-bottom: 8px;
  }
  &.active img {
    opacity: 1;
  }
`;

const ProgressBarContainer = styled.div`
  padding: 0 10px;
`;

const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: 48px auto 48px;
  align-items: center;
  gap: 0 24px;
  @media (max-width: 900px) {
    grid-template-columns: auto;
  }
`;

const Container = styled.div`
  overflow: hidden;
  display: grid;
  gap: 48px;
  justify-content: center;
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 44px auto;
  gap: 24px;
  @media (max-width: 600px) {
    grid-template-columns: auto;
    gap: 16px;
  }
`;

const CardColumn = styled.div`
  background: white;
  flex-grow: 1;
  box-shadow: ${(props) => props.theme.shadows.hard};
  font-size: 21px;
  font-weight: 400;
  padding: 32px;
  margin: 28px 0;
  max-width: 620px;
  border-radius: 16px;
  opacity: 1;
  z-index: 1;
  border: 1px solid var(--theme-border-color);
  grid-area: 1 / 1 / 2 / 3;
  @media (max-width: 1200px) {
    grid-area: 1 / 1 / 3 / 2;
    justify-self: center;
    margin: 0 32px;
    font-size: 18px;
  }
  @media (max-width: 600px) {
    grid-area: 1 / 1 / 2 / 2;
    margin: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 0;
  }
`;

const ImageColumn = styled.div`
  grid-area: 1 / 2 / 2 / 4;
  height: 100%;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: 100%;
    border-radius: 16px;
  }
  @media (max-width: 1200px) {
    grid-area: 2 / 1 / 4 / 2;
  }
  @media (max-width: 600px) {
    grid-area: 2 / 1 / 3 / 2;
    img {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }
`;

const QuoteBody = styled.div`
  font-size: 21px;
  line-height: 27.3px;
`;

const Author = styled.div`
  margin-top: 12px;
  font-weight: 600;
  font-size: 15px;
  line-height: 1;
`;

const AuthorTitle = styled.div`
  font-size: 15px;
  line-height: 1;
  margin-top: 6px;
`;

const LinkWrapper = styled.div`
  font-size: 18px;
  margin-top: 12px;
`;

const CenterGrid = styled.div`
  display: grid;
  grid-area: 1 / 1 / 2 / 2;
  grid-template-columns: 1fr 56px 1.25fr;
  grid-template-rows: 1fr;
  align-items: center;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 56px 1fr;
  }
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
  &.exiting-left {
    animation: ${swipeOutLeft} 320ms;
    transform: translateX(100vh);
    animation-fill-mode: both;
  }
  &.exiting-right {
    animation: ${swipeOutRight} 320ms;
    transform: translateX(100vh);
    animation-fill-mode: both;
  }
  &.entering-left {
    animation: ${swipeInLeft} 520ms;
    animation-delay: 310ms;
    animation-fill-mode: both;
  }
  &.entering-right {
    animation: ${swipeInRight} 520ms;
    animation-delay: 310ms;
    animation-fill-mode: both;
  }
  &.invisible {
    visibility: hidden;
  }
`;

const Wrapper = styled.div``;

export default ({
  intunioModuleTypeId,
  largeQuoteModuleItems = [],
  paddingTop,
  paddingBottom,
  theme,
  anchorId,
} : Props) => {
  const [exitLeft, setExitLeft] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(0);
  const [paused, setPaused] = useState(false);
  const pageRef = useRef<HTMLDivElement>(null);
  const hasMultipleLargeQuoteModuleItems = largeQuoteModuleItems.length > 1;

  const updateCarousel = (val: number) => {
    if (!pageRef.current) return;
    pageRef.current.style.translate = `${val}px 0`;
  };

  const prev = () => {
    if (!hasMultipleLargeQuoteModuleItems) return;
    setExitLeft(true);
    setPaused(false);
    setPreviousIndex(activeIndex);
    setActiveIndex((activeIndex + largeQuoteModuleItems.length - 1) % largeQuoteModuleItems.length);
  };
  const next = () => {
    if (!hasMultipleLargeQuoteModuleItems) return;
    setExitLeft(false);
    setPaused(false);
    setPreviousIndex(activeIndex);
    setActiveIndex((activeIndex + 1) % largeQuoteModuleItems.length);
  };
  const set = (index : number) => {
    if (index === activeIndex) return;
    setPaused(true);
    setExitLeft(index < activeIndex);
    setPreviousIndex(activeIndex);
    setActiveIndex(index);
  };

  const handlers = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: prev,
    onSwiping: (e) => {
      if (!hasMultipleLargeQuoteModuleItems) return;
      updateCarousel(e.deltaX);
    },
  });

  return (
    <ModulePaddingWrapper
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      theme={theme}
      id={anchorId}
    >
      <Wrapper {...handlers}>
        <Container id={intunioModuleTypeId}>
          {hasMultipleLargeQuoteModuleItems && (
            <LogoGrid activeIndex={activeIndex}>
              {largeQuoteModuleItems.map((item, index) => (
                <Logo onClick={() => set(index)} key={item.sys.id} className={index === activeIndex ? 'active' : ''}>
                  {item.logo && (
                    <img
                      height="92"
                      width="108"
                      src={item.logo.url}
                      alt={item.logo.description}
                    />
                  )}
                  {paused ? (
                    <Spacer size={4} />
                  ) : (
                    <ProgressBarContainer>
                      {index === activeIndex && (
                        <ProgressBar duration="10s" onAnimationEnd={next} />
                      )}
                    </ProgressBarContainer>
                  )}
                </Logo>
              ))}
            </LogoGrid>
          )}
          <ButtonGrid>
            {hasMultipleLargeQuoteModuleItems ? (
              <NavigationButton onClick={prev}>
                <LeftSliderButton />
              </NavigationButton>
            ) : (
              <div />
            )}
            <Carousel>
              {largeQuoteModuleItems.map((item, index) => {
                const invisible = index !== activeIndex && index !== previousIndex;
                let className = activeIndex !== index ? `exiting-${exitLeft ? 'left' : 'right'}` : `entering-${exitLeft ? 'right' : 'left'}`;
                className += ` ${invisible ? 'invisible' : ''}`;
                if (pageRef.current) {
                  const oldRef = pageRef.current;

                  // Wait for animation to finish
                  setTimeout(() => {
                    oldRef.style.translate = '';
                  }, 300);
                }
                return (
                  <CenterGrid
                    ref={index === activeIndex ? pageRef : undefined}
                    key={item.sys.id}
                    className={className}
                  >
                    <CardColumn>
                      <CardGrid>
                        <Quotes />
                        <div>
                          <QuoteBody>
                            {item.body}
                          </QuoteBody>
                          <div>
                            <Author>
                              —
                              {' '}
                              {item.author}
                            </Author>
                            <AuthorTitle>
                              {item.authorTitle}
                            </AuthorTitle>
                            {item.link && (
                              <LinkWrapper>
                                <AutoLink as={ArrowLink} {...item.link} />
                              </LinkWrapper>
                            )}
                          </div>
                        </div>
                      </CardGrid>
                    </CardColumn>
                    <ImageColumn>
                      {item.image && (
                        <Image
                          width={item.image.width}
                          height={item.image.height}
                          src={item.image.url}
                          alt={item.image.description || item.image.title}
                          sizes="(max-width: 900px) 90vw, (max-width: 1200px) 78vw, 750px"
                        />
                      )}
                    </ImageColumn>
                  </CenterGrid>
                );
              })}
            </Carousel>
            {hasMultipleLargeQuoteModuleItems ? (
              <NavigationButton onClick={next}>
                <RightSliderButton />
              </NavigationButton>
            ) : (
              <div />
            )}
          </ButtonGrid>
        </Container>
      </Wrapper>
    </ModulePaddingWrapper>
  );
};
